<template>
  <b-container fluid>
    <b-row class="m-2">
      <b-col cols="12">
        <b-button-toolbar key-nav aria-label="Stok/Hizmet İşlemler">
          <b-button to="/Stok/0" variant="success" class="mx-1">Yeni Oluştur</b-button>
          <b-button v-b-toggle.filtreleriAc variant="primary">Filtrele</b-button>
          <b-button-group class="mx-1" v-if="toplamStok > 0" right>
            <download-excel
              :data="excelExportList"
              :footer="excelFooter"
              :header="excelHeader"
              stringifyLongNum:true
              :fields="excelSutunlar"
              escapeCsv:false
              name="Stok Listesi.xls"
            >
              <b-button>Excel</b-button>
            </download-excel>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
      <b-col cols="12">
        <b-collapse id="filtreleriAc">
          <b-card title="Filtreler">
            <b-row>
              <b-col cols="12" lg="4" xl="4">
                Stok/Hizmet Ad
                <b-form-input
                  v-model="filtreler.ad"
                  placeholder="Stok/Hizmet Adı"
                ></b-form-input>
              </b-col>
              <b-col cols="12" lg="4" xl="4">
                Kod
                <b-form-input v-model="filtreler.kod" placeholder="Kod"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" lg="4" xl="4">
                Kategori
                <b-form-input
                  v-model="filtreler.kategorileri"
                  placeholder=""
                ></b-form-input>
              </b-col>
              <b-col cols="12" lg="4" xl="4">
                Marka

                <br>
              
                  <b-dropdown id="dropdown-form" text="Marka" class="m-2 block" align="left" >
                  <b-dropdown-form style="height:200px;
          overflow-y:auto;">

   
                    <b-form-checkbox-group
                      v-model="filtreler.filtreMarkaSecili"
                      :options="
                        $store.state.sirketAyarlar.markaListesi.reduce((acc, post) => {
                          let { Id, ad } = post;

                          return { ...acc, [ad]: (Id, ad) };
                        }, {})
                      "
                    ></b-form-checkbox-group>
 

                  </b-dropdown-form>
                </b-dropdown>
               
              
              </b-col>
            </b-row>
          </b-card>
        </b-collapse>
      </b-col>
    </b-row>
    <b-row class="m-2">
      <b-col cols="12">
        <b-card header-tag="header">
          <template #header>
            <h6 class="mb-0">Stoklar</h6>
            <h4 class="text-muted">
              Stok/Hizmet Yönetimi<b-badge class="badge alert-info m-2"
                >{{ toplamStok }} Adet Stok</b-badge
              >
            </h4>
          </template>

          <b-row>
            <b-col cols="12">
              <div v-show="toplamStok > 0">
                <b-row>
                  <b-col cols="12" xl="6" lg="6">
                    <b-dropdown
                      id="dropdown-form"
                      text="Gizle/Göster"
                      class="m-2"
                      align="left"
                    >
                      <b-dropdown-form>
                        <b-checkbox
                          :disabled="visibleFields.length == 1 && field.visible"
                          v-for="field in kolonlar.filter((o) => o.label != '#')"
                          :key="field.key"
                          v-model="field.visible"
                          inline
                        >
                          {{ field.label }}
                        </b-checkbox>
                      </b-dropdown-form>
                    </b-dropdown>
                  </b-col>

                  <b-col cols="12" xl="6" lg="6">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="toplamStok"
                      :per-page="perPage"
                      align="right"
                      pills
                    ></b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12"> 
              <b-table
                hover
                responsive
                :items="stokFiltred"
                :fields="visibleFields"
                :current-page="currentPage"
                :per-page="perPage"
                show-empty
                label-sort-clear
                label-sort-asc
                label-sort-desc
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                small
                sort-icon-left
                empty-filtered-text="Kayıt Bulunamadı"
                empty-text="Kayıt Bulunamadı"
              > 
                <template #cell(resim1)="data">
               
               
                <img class="img-time-matters" :src="data.value.length == 0 ? require(`../../assets/monoryumLogoTam.png`) : $store.state.portalContentAdres +
                    $store.state.calisilanSirketId + '/images/' + data.value" style="max-width:50px;">
                 
                
                  
                </template>
                <template #cell(kayitTarihi)="data">
                  {{ data.value | moment("DD.MM.YYYY hh:ss") }}
                </template>

                <template #cell(aktif)="data">
                  {{ data.value ? "Aktif" : "Pasif" }}
                </template>

                <template #cell(mevcutAdet)="data">
                  <div class="paraKolonu ortaKolonUzunluk">
                    {{ data.value }}
                  </div>
                </template>

                <template #cell(actions)="data">
                  <b-dropdown right>
                    <b-dropdown-item :to="'/Stok/' + data.item.Id"
                      >Düzenle</b-dropdown-item
                    >
                    <b-dropdown-item>Hareketleri</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item>Sil</b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <div v-show="toplamStok > 0">
                <b-row>
                  <b-col cols="12" xl="12" lg="12">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="toplamStok"
                      :per-page="perPage"
                      align="right"
                      pills
                    ></b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import moment from "moment";
import VueMoment from "vue-moment";
require("moment/locale/tr");
moment.locale("tr");
export default {
  data() {
    return {
      currentPage: 1,
      perPage: 150,
      toplamStok: 0,
      excelExportList: [],
      excelSutunlar: {},
      excelFooter: "Monoryum E-Dönüşüm Sistemi",
      excelHeader: "",
      sortBy: "kod",
      sortByModal: "ad",
      ss: "d-none",
      sortDesc: true,
      filtreler: {
        ad: undefined,
        kod: undefined,
        aktif: undefined,
        b2bAktif: undefined,
        barkod: undefined,
        gtin: undefined,
        mevcutAdet: undefined,
        filtreMarkaSecili: [],
      },
      kolonlar: [
        {
          key: "kayitTarihi",
          sortable: true,
          label: "K.Tarihi",
          visible: false,
        },
        {
          key: "resim1",
          sortable: true,
          label: "Resim",
          visible: true,
        },
        {
          key: "kod",
          sortable: true,
          label: "Kod",
          visible: true,
        },
        {
          key: "ad",
          sortable: true,
          label: "Ad",
          visible: true,
        },
        {
          key: "markasi",
          sortable: true,
          label: "Marka",
          visible: false,
        },
        {
          key: "aktif",
          sortable: true,
          label: "Aktif",
          visible: false,
        },
        {
          key: "barkod",
          sortable: true,
          label: "Barkod",
          visible: false,
        },
        {
          key: "mevcutAdet",
          sortable: true,
          label: "Mevcut",
          visible: true,
        },

        { key: "actions", label: "#", visible: true },
      ],
    };
  },
  computed: {
    visibleFields() {
      return this.kolonlar.filter((field) => field.visible);
    },
    stokFiltred: function () {
      var filtered = this.$store.state.sirketAyarlar.tumStoklar;

      if (this.filtreler.kod) {
        const regexp = new RegExp(this.filtreler.kod, "i");
        filtered = filtered.filter((lesson) => lesson.kod.match(regexp));
      }
      if (this.filtreler.ad) {
        const regexp = new RegExp(this.filtreler.ad, "i");
        filtered = filtered.filter((lesson) => lesson.ad.match(regexp));
      }

      if (this.filtreler.filtreMarkaSecili.length > 0) {
        filtered = filtered.filter((el) => {
          return this.filtreler.filtreMarkaSecili.some((f) => {
            return f === el.markasi
          });
        });
      }

      this.toplamStok = filtered.length;

      var obj = this.visibleFields
        .filter((o) => o.key != "actions")
        .reduce((acc, post) => {
          let { key, label } = post;

          return { ...acc, [label]: (label, key) };
        }, {});

      this.excelSutunlar = obj;
      this.excelExportList = [];

      for (let dt in filtered) {

        this.excelExportList.push({
          kod: filtered[dt].kod,
          ad: filtered[dt].ad,
          kayitTarihi: moment(filtered[dt].kayitTarihi).format("DD.MM.YYYY"),
          markasi: filtered[dt].markasi,
          mevcutAdet:  filtered[dt].mevcutAdet,
          barkod:  filtered[dt].barkod
          
        });
      }

      return filtered;
    },
  },
  created(){
    if(this.$store.state.sirketAyarlar.tumStoklar.length<=0){
      this.$store.dispatch("sirketStoklar", { stokId: 0, gizliAl:false })
    }
    
  }
};
</script>

<style scoped></style>
